import React from "react"

// Types
import { IIconProps } from "./Icon"

const QuestionMark: React.FCS<IIconProps> = ({
  className,
  width,
  height,
  color,
}) => (
  <svg
    style={{
      width: `${String(width)}px`,
      height: `${String(height)}px`,
    }}
    viewBox="0 0 14 24"
    className={className}
    fill="none"
  >
    <path
      d="M6.18 4.222a8.758 8.758 0 0 0-2.71.414c-1.127.36-2.401-.109-2.823-1.213l-.06-.156C.247 2.373.581 1.345 1.47.99A12.013 12.013 0 0 1 3.141.465C4.348.155 5.531 0 6.693 0c1.457 0 2.652.188 3.585.565.956.377 1.707.853 2.254 1.43.546.576.922 1.207 1.127 1.894.227.687.341 1.34.341 1.961 0 .62-.102 1.197-.307 1.729-.182.51-.421.986-.717 1.43a8.955 8.955 0 0 1-1.025 1.262c-.387.4-.762.776-1.127 1.13-.273.267-.557.555-.853.865-.296.288-.57.598-.82.93a6.046 6.046 0 0 0-.58 1.064c-.16.355-.24.72-.24 1.097v.4c0 .243-.188.465-.432.465H4.597c-.43 0-.804-.303-.84-.732v-.731c0-.62.079-1.175.238-1.662.16-.51.364-.975.615-1.396.273-.443.58-.853.922-1.23.341-.377.694-.742 1.058-1.097a28.391 28.391 0 0 0 1.434-1.496 2.6 2.6 0 0 0 .65-1.728c0-.532-.217-.987-.65-1.363C7.614 4.41 7 4.222 6.18 4.222Zm3.176 16.72c0 .975-.33 1.728-.99 2.26A3.315 3.315 0 0 1 6.18 24c-.819 0-1.559-.266-2.219-.798-.637-.532-.956-1.285-.956-2.26 0-.975.319-1.729.956-2.26.66-.532 1.4-.798 2.22-.798.819 0 1.547.266 2.185.797.66.532.99 1.286.99 2.26Z"
      fill={color}
    />
  </svg>
)

export default QuestionMark
